// import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { logout } from "./actions/authAction";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    async function fetchData() {
      fetch("/jwt/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        signal,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.auth === false) {
            dispatch(logout());
            window.location.href = "/login";
          }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("Aborted");
          } else {
            Sentry.captureException(err);
          }
        });
    }

    fetchData();

    return () => {
      controller.abort();
    };
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
