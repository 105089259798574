import CustomDatePicker from "components/Table/CustomDatePicker";
import TableComponent from "components/Table/Table.js";
import useDocumentTitle from "../../useDocumentTitle";
import { Row, Card, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Transactions = () => {
  useDocumentTitle("LottoPawa | My Transactions");
  const { t } = useTranslation();
  const [balance, setBalance] = useState(0);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchBalance() {
      try {
        await fetch("/api/v1/users/balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setBalance(result.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchBalance();
  }, [token]);

  const columns = [
    {
      title: t("transactions.fields.transaction-id"),
      field: "transaction_id",
      render: (rowData) => rowData.transaction_id.toUpperCase(),
    },
    {
      title: t("transactions.fields.type"),
      field: "transaction_type",
      render: (rowData) => rowData.transaction_type.toUpperCase(),
    },
    {
      title: t("transactions.fields.date"),
      field: "transaction_date",
      render: (rowData) => new Date(rowData.transaction_date).toLocaleString(),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("transactions.fields.balance-before"),
      field: "balance_before",
      filtering: false,
      render: (rowData) => {
        return rowData.balance_before.toLocaleString("en-KE", {
          style: "currency",
          currency: "Ksh",
        });
      },
    },
    {
      title: t("transactions.fields.amount"),
      field: "amount",
      render: (rowData) => {
        return rowData.amount.toLocaleString("en-KE", {
          style: "currency",
          currency: "Ksh",
        });
      },
    },
    {
      title: t("transactions.fields.balance-after"),
      field: "balance_after",
      filtering: false,
      render: (rowData) => {
        return rowData.balance_after.toLocaleString("en-KE", {
          style: "currency",
          currency: "Ksh",
        });
      },
    },
    {
      title: t("transactions.fields.status"),
      field: "status",
      render: (rowData) => {
        if (rowData.status === "pending") {
          return (
            <Badge color="info" className="badge-dot mr-4">
              <i className="bg-info" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "success") {
          return (
            <Badge color="success" className="badge-dot mr-4">
              <i className="bg-success" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "failed") {
          return (
            <Badge color="danger" className="badge-dot mr-4">
              <i className="bg-danger" />
              {rowData.status}
            </Badge>
          );
        }
      },
    },
  ];

  const title =
    t("transactions.title") +
    ` (Balance: ${balance.toLocaleString("en-KE", {
      style: "currency",
      currency: "Ksh",
    })})`;

  return (
    <Row>
      <div className="col-12">
        <Card className="shadow">
          <TableComponent
            url="/api/v1/userTransactions?"
            columns={columns}
            title={title}
          />
        </Card>
      </div>
    </Row>
  );
};

export default Transactions;
