/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";

const AuthNavbar = () => {
  const { t } = useTranslation();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-lottopawa-card opacity-9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <a className="navbar-brand" href="#">
                <img
                  src={require("assets/img/brand/Lottopawa-yellow.png")}
                  alt=""
                  width="300"
                  height="100%"
                />
              </a>
            </div>
            <div className="col-6 justify-content-end d-flex">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggle"
                aria-controls="navbarToggle"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div
            className="collapse navbar-collapse bg-lottopawa-card opacity-9"
            id="navbarToggle"
          >
            <div className="navbar-collapse-header d-md-none">
              <div className="row">
                <div className="col-6">
                  <a className="navbar-brand" href="#">
                    <img
                      src={require("assets/img/brand/Lottopawa-yellow.png")}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </a>
                </div>
                <div className="col-6 collapse-close">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarToggle"
                    aria-controls="navbarToggle"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active text-lottopawa-yellow"
                  aria-current="page"
                  href="https://lottopawa.co.ke/"
                >
                  {t("navbar.home")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-lottopawa-yellow"
                  href="https://lottopawa.co.ke/how-much-can-i-win-at-lottopawa/"
                >
                  {t("navbar.how-to-win")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-lottopawa-yellow"
                  href="https://lottopawa.co.ke/help/how-do-i-get-a-ticket-for-lottopawa/"
                >
                  {t("navbar.how-to-play")}
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  href="#"
                  aria-haspopup="true"
                  className="nav-link dropdown-toggle text-lottopawa-yellow"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("navbar.results")}
                </a>
                <ul
                  className="dropdown-menu bg-lottopawa-card opacity-9"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item text-lottopawa-yellow"
                      href="https://lottopawa.co.ke/lottopawa-draw-results/"
                    >
                      {t("navbar.draw-results")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item text-lottopawa-yellow"
                      href="https://lottopawa.co.ke/winners-results/"
                    >
                      {t("navbar.list-of-winners")}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-lottopawa-yellow"
                  href="https://lottopawa.co.ke/help/category/faqs/"
                >
                  {t("navbar.faq")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-lottopawa-yellow"
                  href="https://lottopawa.co.ke/contact-us/"
                >
                  {t("navbar.help")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AuthNavbar;
