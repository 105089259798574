import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: ["navigator"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      de: {
        translations: require("./locales/de/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
