import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
  const [totalTicket, setTotalTicket] = useState([]);
  const [upcomingTicket, setUpcomingTicket] = useState([]);
  const [pastTicket, setPastTicket] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch("/api/v1/userLotteriesCount", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setTotalTicket(result.total);
            setUpcomingTicket(result.pending);
            setPastTicket(result.drawn);
          });
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [token]);

  return (
    <div className="row mb-3">
      <Col lg="6" xl="3">
        <Card className="bg-lottopawa-card card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <CardBody>
              <div className="row">
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-lottopawa-yellow mb-0"
                  >
                    All My tickets
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {totalTicket}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="fas fa-gamepad" />
                  </div>
                </Col>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg="6" xl="3">
        <Card className="bg-lottopawa-card card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <CardBody>
              <div className="row">
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-lottopawa-yellow mb-0"
                  >
                    Tickets for Upcoming draws
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {upcomingTicket}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                    <i className="fas fa-puzzle-piece" />
                  </div>
                </Col>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg="6" xl="3">
        <Card className="bg-lottopawa-card card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <CardBody>
              <div className="row">
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-lottopawa-yellow mb-0"
                  >
                    Tickets from Past draws
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {pastTicket}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i className="fas fa-trophy" />
                  </div>
                </Col>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg="6" xl="3">
        <Card className="bg-lottopawa-card card-stats mb-4 mb-xl-0">
          <Link to="/transactions">
            <CardBody>
              <div className="row">
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-lottopawa-yellow mb-0"
                  >
                    my transactions
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0"></span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i className="fas fa-money-bill-transfer" />
                  </div>
                </Col>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
    </div>
  );
};

export default Header;
