import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
      className="footer bg-lottopawa-green m-auto"
      style={{ fontFamily: "Inter", fontWeight: "400" }}
    >
      <div className="row align-items-center justify-content-md-between mb-3">
        <div className="col-12 col-md-12 mb-2">
          <p className="m-0 text-white" style={{ fontSize: "15px" }}>
            Lottopawa dedicates 25% of its profits to charities. While you play
            to win big, you also support good causes within the Kenyan society.
            <a
              href="https://lottopawa.co.ke/our-charity-work/"
              rel="noopener noreferrer"
              className="text-lottopawa-yellow mx-1"
            >
              Find out more here
            </a>
          </p>
        </div>
      </div>

      <div
        className="row align-items-center justify-content-between mb-3"
        style={{ fontSize: "18px" }}
      >
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.about-us")}
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.charity-work")}
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.privacy-policy")}
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.terms-and-conditions")}
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.help")}
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="copyright text-center text-xl-left text-white">
            <a
              className="font-weight-bold ml-1 text-white"
              href="https://lottopawa.co.ke"
              rel="noopener noreferrer"
            >
              {t("footer.afiliate-program")}
            </a>
          </div>
        </div>
      </div>

      <div
        className="row align-items-center justify-content-between mb-3"
        style={{ fontSize: "18px" }}
      >
        <div className="col-12 col-md-12">
          <div className="text-center text-white">
            <p className="m-0">{t("footer.text-2")}</p>
            <a
              href="https://www.begambleaware.org/"
              rel="noopener noreferrer"
              target="_blank"
              className="text-lottopawa-yellow"
            >
              {t("footer.text-3")}
            </a>
          </div>
        </div>
      </div>

      <div
        className="row align-items-center justify-content-between mb-3"
        style={{ fontSize: "18px" }}
      >
        <div className="col-12 text-center">
          <p className="m-0 text-white">
            MOBIPESA LIMITED trading as LOTTOPAWA lottery is licensed by BCLB
            (Betting Control and Licensing Board of Kenya) under the Betting,
            Lotteries and Gaming Act, Cap 131, Laws of Kenya under the license
            number: PL0000884.
          </p>
        </div>
      </div>

      <div className="row align-items-center" style={{ fontSize: "18px" }}>
        <div className="col-12 text-center">
          <p className="m-0 text-white">All rights reserved 2023.® LottoPawa</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
