import Toast from "components/Toast";
import { Container } from "reactstrap";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { login } from "../../actions/authAction";
import React, { useState, useEffect } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import AuthNavbar from "components/Navbars/AuthNavbar";
import { useSelector, useDispatch } from "react-redux";
// import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import LoginFooter from "components/Footers/LoginFooter.js";

function Login() {
  const [authToken, setAuthToken] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [error, setError] = useState(null);
  const loggedIn = useSelector((state) => state.auth.token);
  const history = useHistory();
  useDocumentTitle("LottoPawa | Login");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const jwtToken = new URLSearchParams(search).get("authToken");
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // Check if user is logged in and push to dashboard
    if (loggedIn) {
      fetch("/jwt/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loggedIn}`,
        },
        signal,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.auth === true) {
            notify(
              "error",
              "You already have an active session. Please log out first"
            );

            setTimeout(() => {
              history.push("/dashboard");
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("Aborted");
          }
        });
    }

    if (jwtToken) {
      setAuthToken(jwtToken);
      async function verifyToken() {
        fetch("/jwt/verify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          signal,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.auth === true) {
              Sentry.setUser({ id: data.user_id });
              dispatch(login(jwtToken, "None"));
              window.location.href = "/dashboard";
            } else if (data.auth === false) {
              setAuthError(data.message);
              // window.location.href = "/login";
            }
          })
          .catch((err) => {
            if (err.name === "AbortError") {
              console.log("Aborted");
            }
          });
      }

      verifyToken();
    }

    return () => {
      controller.abort();
    };
  }, [dispatch, history, jwtToken, loggedIn, notify]);

  const Login = (e) => {
    e.preventDefault();
    const phone_number = e.target.phone_number.value;
    const password = e.target.password.value;
    const data = {
      phone_number: phone_number,
      password: password,
    };
    fetch("login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        Sentry.setUser({ id: data.user_id });
        dispatch(login(data.token, data.username));
        e.target.phone_number.value = "";
        e.target.password.value = "";
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <AuthNavbar />
      <Container>
        <div
          className="row align-items-center justify-content-center"
          style={{ minHeight: "60vh", fontFamily: "Inter", fontWeight: "400" }}
        >
          {authToken && (
            <div className="col-12 col-md-5 text-center">
              <div className="row">
                <img
                  alt=""
                  className="img-fluid"
                  src={require("assets/img/brand/jackpot-sack.png")}
                />
                {!authError && <span className="auth-loader"></span>}
                {authError && (
                  <div className="mask text-light d-flex justify-content-center flex-column text-center">
                    <div className="alert alert-danger" role="alert">
                      <strong>Oh snap!</strong> {authError}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <img
                  alt=""
                  className="img-fluid"
                  src={require("assets/img/brand/200-million.png")}
                />
              </div>
            </div>
          )}

          {!authToken && (
            <div className="col-12 col-md-5">
              <div className="card bg-lottopawa-card shadow border-0">
                <div className="card-header bg-transparent pb-5">
                  <div className="text-center mt-2 mb-3">
                    <img
                      alt="Lottopawa Logo"
                      className="img-fluid"
                      src={require("assets/img/brand/Lottopawa-yellow.png")}
                      style={{ width: "200px" }}
                    />
                    <p className="text-white">{t("auth.login")}</p>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        <strong>Oh snap!</strong> {error}
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={Login}>
                    <div className="form-group mb-3">
                      <label
                        className="form-control-label text-white"
                        htmlFor="phone_number"
                      >
                        {t("auth.phone-number")}
                      </label>
                      <input
                        className="form-control"
                        id="phone_number"
                        type="number"
                        placeholder="07xxxxxxxx/01xxxxxxxx/254xxxxxxxxx"
                        style={{ color: "black" }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        className="form-control-label text-white"
                        htmlFor="password"
                      >
                        {t("auth.password")}
                      </label>
                      <input
                        className="form-control"
                        id="password"
                        type="password"
                        placeholder="********"
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-lottopawa-yellow text-dark my-4 btn-block"
                        type="submit"
                      >
                        {t("auth.login")}
                      </button>
                    </div>

                    <div className="text-center">
                      <a href="https://lottopawa.co.ke/reset/">
                        {t("auth.forgot-password")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <LoginFooter />
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </>
  );
}

export default Login;
