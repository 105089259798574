import parse from "html-react-parser";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const ViewTicketBody = ({ ticket }) => {
  return (
    <Col xl="12" className="mb-2">
      <Card className="shadow">
        <CardHeader className="bg-white border-0 pl-1">
          <Row className="">
            <Col className="d-flex flex-row" xs="8">
              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                <i className="fas fa-user" />
              </div>
              <div className="pl-2">
                <span>
                  {ticket.lottopawa_users
                    ? "ME"
                    : ticket.operator_users
                    ? ticket.operator_users?.first_name
                    : ""}{" "}
                  {ticket.lottopawa_users
                    ? null
                    : ticket.operator_users
                    ? ticket.operator_users?.last_name
                    : ""}
                </span>
                <br />
                <span>
                  {ticket.lottopawa_users
                    ? ticket.lottopawa_users?.user_email
                    : ""}
                </span>
              </div>
            </Col>
            <Col className="text-right" xs="4">
              {ticket.opened_at && (
                <span className="text-muted">
                  {new Date(ticket.opened_at).toLocaleString()}
                </span>
              )}
              {ticket.reply_at && (
                <span className="text-muted">
                  {new Date(ticket.reply_at).toLocaleString()}
                </span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="pl-4">
            <div>{ticket.description ? parse(ticket.description) : ""}</div>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ViewTicketBody;
