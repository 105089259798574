export const authReducer = (state = { token: "", username: "" }, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        token: action.payload.token,
        username: action.payload.username,
      };
    case "LOGOUT":
      return { token: "", username: "" };
    default:
      return state;
  }
};
