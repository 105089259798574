import { Button, FormGroup, Input, Col, Badge, InputGroup } from "reactstrap";
import CustomDatePicker from "components/Table/CustomDatePicker";
import TableComponent from "components/Table/Table.js";
import React, { useEffect, useState } from "react";
import TopCards from "components/Dashboard/TopCards";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Toast from "components/Toast";
import useDocumentTitle from "../../useDocumentTitle";

const Dashboard = () => {
  useDocumentTitle("LottoPawa | Dashboard");
  const [referralData, setReferralData] = React.useState(null);
  const token = useSelector((state) => state.auth.token);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  useEffect(() => {
    if (token) {
      fetch("/api/v1/users/phone", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPhoneNumber(data.phone_number.phone_number);
        })
        .catch((error) => {
          console.log(error);
        });
      fetch(`/api/v1/referrals`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setReferralData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      history.push("/login");
    }
  }, [token, history]);

  const handleWithdrawalRequest = async (e) => {
    e.preventDefault();
    if (
      e.target.withdraw_amount?.value === "" ||
      e.target.withdraw_amount?.value === 0
    )
      return;
    const data = {
      amount: e.target.withdraw_amount.value,
    };

    submitWithdrawal(data);
    e.target.withdraw_amount.value = "";
  };

  async function submitWithdrawal(data) {
    try {
      await fetch("api/v1/users/withdraw", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          if (data.message === "provide details")
            return notify(
              "error",
              "Please complete your profile first to make this withdrawal request"
            );
          notify("success", data.message);
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  }

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (
      e.target.deposit_amount?.value === "" ||
      e.target.deposit_amount?.value === 0
    )
      return;
    const data = {
      amount: e.target.deposit_amount.value,
    };

    submitDeposit(data);
    e.target.deposit_amount.value = "";
  };

  async function submitDeposit(data) {
    try {
      await fetch("/api/v1/mobipesa/transaction_reference", {
        method: "POST",
        body: JSON.stringify({
          phone_number: phoneNumber,
          amount: data.amount,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then(async (responseData) => {
          await fetch("/api/v1/mobipesa/stk_push", {
            method: "POST",
            body: new URLSearchParams({
              amount: data.amount ? data.amount : null,
              msisdn: phoneNumber ? phoneNumber : null,
              reference: responseData.reference,
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + token,
            },
          })
            .then(async (response) => {
              if (response.ok) {
                return response.json();
              }
              return response.text().then((text) => {
                throw new Error(text);
              });
            })
            .then((data) => {
              if (data.status === "success") {
                notify(
                  "success",
                  t("user-profile.notifications.deposit-success")
                );
              } else {
                notify("error", t("user-profile.notifications.deposit-error"));
              }
            })
            .catch((error) =>
              notify("error", t("user-profile.notifications.deposit-error"))
            );
        })
        .catch((error) => {
          notify("error", t("user-profile.notifications.deposit-error"));
        });
    } catch (error) {
      notify("error", t("user-profile.notifications.deposit-error"));
    }
  }

  const columns = [
    {
      title: t("lotteries.fields.ticket-id"),
      field: "transaction_id",
      sorting: false,
    },
    {
      title: t("lotteries.fields.purchase-date"),
      field: "purchased_at",
      sorting: false,
      render: (rowData) =>
        new Date(rowData.purchased_at).toLocaleString("en-GB", {
          hour12: true,
        }),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("lotteries.fields.draw-date"),
      field: "draw_date",
      sorting: false,
      render: (rowData) => new Date(rowData.draw_date).toDateString(),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("lotteries.fields.amount"),
      field: "price",
      sorting: false,
      render: (rowData) => {
        return rowData.price.toLocaleString("en-KE", {
          style: "currency",
          currency: "Ksh",
          minimumFractionDigits: 2,
        });
      },
    },
    {
      title: t("lotteries.fields.status"),
      field: "status",
      sorting: false,
      render: (rowData) => {
        if (rowData.status === "won") {
          return (
            <Badge color="success" className="badge-dot mr-4">
              <i className="bg-success" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "not-won") {
          return (
            <Badge color="danger" className="badge-dot mr-4">
              <i className="bg-danger" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "pending-draw") {
          return (
            <Badge color="info" className="badge-dot mr-4">
              <i className="bg-info" />
              {rowData.status}
            </Badge>
          );
        }
      },
    },
    {
      title: t("lotteries.fields.win-amount"),
      field: "win_amount",
      filtering: false,
      sorting: false,
      render: (rowData) =>
        rowData.win_amount
          ? rowData.win_amount.toLocaleString("en-KE", {
              style: "currency",
              currency: "Ksh",
              minimumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("lotteries.fields.action"),
      field: "action",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <button
            className="btn btn-lottopawa-yellow"
            onClick={() => {
              history.push(`/my-ticket/${rowData.transaction_id}`);
            }}
          >
            {t("lotteries.fields.view")}
          </button>
        );
      },
    },
  ];

  return (
    <>
      <TopCards />
      {referralData && (
        <div className="row mb-3">
          <div className="col-12 col-lg-6 col-xl-3 mb-2">
            <div className="card bg-secondary shadow">
              <div className="card-header bg-lottopawa-card border-0">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h3 className="mb-0 text-lottopawa-yellow">
                      {t("referral.fields.referral-link")}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-12 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <Input
                        value={referralData && referralData?.referral_link}
                        type="text"
                        readOnly={true}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            referralData?.referral_link
                          );
                          notify("success", "Copied to clipboard");
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button
                      className="btn-icon ml-3 btn-md rounded-pill"
                      color="lottopawa-yellow"
                      type="button"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          referralData?.referral_link
                        );
                        notify("success", "Copied to clipboard");
                      }}
                    >
                      <span className="btn-inner--icon text-dark">
                        <i className="fas fa-copy" />
                      </span>
                      <span className="btn-inner--text text-dark">
                        {t("referral.fields.copy-link")}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="card bg-lottopawa-card card-stats mb-4 mb-xl-0">
              <div className="card-body" style={{ height: "150px" }}>
                <div className="row">
                  <div className="col">
                    <h5 className="text-uppercase text-lottopawa-yellow mb-0">
                      {t("referral.fields.signups")}
                    </h5>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {referralData && referralData?.signup_count}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-users-viewfinder fa-beat" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="card bg-lottopawa-card card-stats mb-4 mb-xl-0">
              <div className="card-body" style={{ height: "150px" }}>
                <div className="row">
                  <div className="col">
                    <h5 className="text-uppercase text-lottopawa-yellow mb-0">
                      {t("referral.fields.signup-purchase")}
                    </h5>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {referralData && referralData?.conversion}
                    </span>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i className="fas fa-shop-lock fa-beat" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="card bg-lottopawa-card card-stats mb-4 mb-xl-0">
              <div className="card-header bg-lottopawa-card pb-0">
                <div className="row">
                  <div className="col">
                    <h5 className="text-uppercase text-lottopawa-yellow mb-0">
                      {t("referral.fields.my-bonus")}
                    </h5>
                  </div>
                  <div className="col-auto">
                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fa-solid fa-gift fa-shake" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {referralData && referralData?.activeBonus === "wheel" && (
                  <div>
                    <div className="row">
                      <div className="col">
                        <span className="h4 font-weight-bold text-white mb-0">
                          {t("referral.fields.silver-wheel")}:{" "}
                          {referralData?.silver_wheel}
                        </span>
                        <span className="h4 font-weight-bold text-white mb-0 mx-2">
                          {t("referral.fields.golden-wheel")}:{" "}
                          {referralData?.golden_wheel}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <a
                          href="https://lottopawa.co.ke/lotopawa-silver-wheel/"
                          className="btn bg-lottopawa-yellow ml-3 btn-md rounded-pill"
                        >
                          <span className="btn-inner-text text-dark">
                            {t("referral.fields.spin-silver")}
                          </span>
                        </a>
                      </div>
                      <div className="col-6">
                        <a
                          href="https://lottopawa.co.ke/lotopawa-golden-wheel/"
                          className="btn bg-lottopawa-yellow ml-3 btn-md rounded-pill"
                        >
                          <span className="btn-inner-text text-dark">
                            {t("referral.fields.spin-golden")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {referralData && referralData?.activeBonus === "ticket" && (
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <span className="h4 font-weight-bold text-white mb-0">
                          {referralData?.bonus_ticket ? (
                            new Date(referralData?.bonus_ticket) <
                            new Date() ? (
                              <span className="text-danger">
                                {t("referral.fields.bonus-ticket-expired", {
                                  drawDate: new Date(
                                    referralData?.bonus_ticket
                                  ).toDateString(),
                                })}
                              </span>
                            ) : (
                              <span className="text-success">
                                {t("referral.fields.bonus-ticket", {
                                  drawDate: new Date(
                                    referralData?.bonus_ticket
                                  ).toDateString(),
                                })}
                              </span>
                            )
                          ) : (
                            <span className="h4 font-weight-bold text-white mb-0">
                              {t("referral.fields.no-bonus")}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mb-3">
        <Col className="order-xl-1 mb-2" xl="6">
          <div className="card bg-secondary shadow">
            <div className="card-header bg-lottopawa-card border-0 py-1">
              <h3 className="mb-0 text-capitalize text-lottopawa-yellow">
                {t("user-profile.headings.deposit")}
              </h3>
              <h6 className="heading-small text-white mb-0">
                {t("user-profile.headings.deposit-info")}
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleDeposit}>
                <div className="pl-lg-4">
                  <div className="row">
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-amount"
                        >
                          {t("user-profile.fields.amount")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-amount"
                          placeholder="Amount"
                          type="number"
                          name="deposit_amount"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <Button
                        color="lottopawa-yellow text-dark"
                        type="submit"
                        className="mt-4"
                      >
                        <span className="btn-inner--icon text-dark">
                          <i className="fas fa-money-bill"></i>
                        </span>
                        <span className="btn-inner--text text-dark">
                          {t("user-profile.buttons.deposit")}
                        </span>
                      </Button>
                    </Col>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Col>
        <Col className="order-xl-2" xl="6">
          <div className="card bg-secondary shadow">
            <div className="card-header bg-lottopawa-card border-0 py-1">
              <h3 className="mb-0 text-capitalize text-lottopawa-yellow">
                {t("user-profile.headings.withdraw")}
              </h3>
              <h6 className="heading-small text-white mb-0">
                {t("user-profile.headings.withdraw-info")}
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleWithdrawalRequest}>
                <div className="pl-lg-4">
                  <div className="row">
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-withdraw-amount"
                        >
                          {t("user-profile.fields.amount")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-withdraw-amount"
                          placeholder="Amount"
                          type="number"
                          name="withdraw_amount"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-0">
                        <Button
                          className="btn-icon mt-4"
                          color="lottopawa-yellow"
                        >
                          <span className="btn-inner--icon text-dark">
                            <i className="fas fa-wallet"></i>
                          </span>
                          <span className="btn-inner--text text-dark">
                            {t("user-profile.buttons.withdraw")}
                          </span>
                        </Button>
                      </FormGroup>
                    </Col>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <TableComponent
            title={t("lotteries.my-lotteries")}
            columns={columns}
            url="/api/v1/userLotteries/all?"
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
