import CustomDatePicker from "components/Table/CustomDatePicker";
import { Row, Badge, Card, CardHeader, Col } from "reactstrap";
import useDocumentTitle from "../../useDocumentTitle";
import TableComponent from "components/Table/Table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Tickets = () => {
  useDocumentTitle("LottoPawa | Support Tickets");
  const history = useHistory();
  const { t } = useTranslation();

  const handleTicketCreate = () => {
    history.push(`/support-ticket/new`);
  };

  const columns = [
    { title: t("tickets.fields.ticket-id"), field: "id" },
    { title: t("tickets.fields.subject"), field: "subject" },
    {
      title: t("tickets.fields.status"),
      field: "status",
      render: (rowData) => {
        return (
          <Badge
            color={`${
              rowData.status === "open"
                ? "success"
                : rowData.status === "closed"
                ? "danger"
                : "info"
            }`}
            className="badge-dot mr-4"
          >
            <i
              className={`${
                rowData.status === "open"
                  ? "bg-success"
                  : rowData.status === "closed"
                  ? "bg-danger"
                  : "bg-info"
              }`}
            />
            {rowData.status}
          </Badge>
        );
      },
    },
    {
      title: t("tickets.fields.opened-at"),
      field: "opened_at",
      render: (rowData) => new Date(rowData.opened_at).toLocaleString(),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("tickets.fields.action"),
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <button
          className="btn btn-lottopawa-yellow"
          onClick={() => history.push(`/support-ticket/view/${rowData.id}`)}
        >
          {t("tickets.fields.view")}
        </button>
      ),
    },
  ];

  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0 bg-lottopawa-card">
            <Row className="align-items-center">
              <Col className="text-right" xs="12">
                <button
                  className="btn btn-lg btn-lottopawa-yellow"
                  onClick={handleTicketCreate}
                >
                  {t("tickets.new-ticket")}
                </button>
              </Col>
            </Row>
          </CardHeader>
          <TableComponent
            title={t("tickets.title")}
            url="/api/v1/tickets?"
            columns={columns}
          />
        </Card>
      </div>
    </Row>
  );
};

export default Tickets;
