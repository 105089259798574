import { useLocation, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "components/Sidebar/Sidebar.js";
import ProtectedRoutes from "../ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "../ErrorBoundary";
import * as Sentry from "@sentry/react";
import React from "react";

import routes from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent && mainContent.current) mainContent.current.scrollTop = 0;
  }, [dispatch, location, token]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <ProtectedRoutes
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
        // return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "LottoPawa";
  };

  return (
    <>
      {token ? (
        <main>
          <Sidebar
            {...props}
            routes={routes}
            logo={{
              outterLink: "https://lottopawa.co.ke",
              imgSrc: require("../assets/img/brand/Lottopawa.png"),
              imgAlt: "LottoPawa application logo",
            }}
          />
          <div className="main-content" ref={mainContent}>
            <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
            />
            <div
              className="container-fluid py-7 px-0"
              style={{ minHeight: "85vh" }}
            >
              <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
                <Switch>
                  {getRoutes(routes)}
                  {/* Redirect to dashboard if route not found */}
                  <Redirect from="*" to="/dashboard" />
                </Switch>
              </Sentry.ErrorBoundary>
            </div>
            <AdminFooter />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            draggable={false}
            pauseOnVisibilityChange
            closeOnClick
            pauseOnHover
          />
        </main>
      ) : (
        // redirect to lottopawa for demo use
        (window.location.href = "https://lottopawa.co.ke/logout")
      )}
    </>
  );
};

export default Admin;
