import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../useDocumentTitle";
import React, { useEffect, useState } from "react";
import { logout } from "../../actions/authAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Toast from "components/Toast";

const Profile = () => {
  useDocumentTitle("LottoPawa | My Profile");
  const [referralData, setReferralData] = useState(null);
  const [player, setPlayer] = useState([{}]);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!token) history.push("/login");

    async function fetchData() {
      try {
        await fetch("api/v1/users/profile", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            return response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((data) => setPlayer(data))
          .catch((error) => notify("error", error.message));
      } catch (error) {
        notify("error", error.message);
      }
    }

    fetch("/api/v1/users/phone", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        setPhoneNumber(data.phone_number.phone_number);
      })
      .catch((error) => {
        notify("error", error.message);
      });

    fetch(`/api/v1/referrals`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        setReferralData(data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchData();
  }, [token, history, notify]);

  const handleUserProfileSubmit = (e) => {
    e.preventDefault();
    const data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      address: e.target.address.value,
      phone_number: e.target.phone_number.value,
      national_id: e.target.national_id.value,
    };

    submitData(data);
  };

  async function submitData(data) {
    try {
      await fetch("api/v1/users/profile", {
        method: "PUT",
        body: new URLSearchParams({
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          address: data.address,
          national_id: data.national_id,
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => notify("success", data.message))
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  }

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (e.target.password?.value === "" || e.target.password?.value === 0)
      return;
    if (
      e.target.passwordConfirmation?.value === "" ||
      e.target.passwordConfirmation?.value === 0
    )
      return;

    const data = {
      password: e.target.password.value,
      confirm_password: e.target.passwordConfirmation.value,
    };

    await submitPassword(data);
    e.target.password.value = "";
    e.target.passwordConfirmation.value = "";
  };

  async function submitPassword(data) {
    try {
      await fetch("api/v1/users/password", {
        method: "PUT",
        body: new URLSearchParams(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => notify("success", data.message))
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  }

  const handleWithdrawalRequest = async (e) => {
    e.preventDefault();
    if (
      e.target.withdraw_amount?.value === "" ||
      e.target.withdraw_amount?.value === 0
    )
      return;
    const data = {
      amount: e.target.withdraw_amount.value,
    };

    submitWithdrawal(data);
    e.target.withdraw_amount.value = "";
  };

  async function submitWithdrawal(data) {
    try {
      await fetch("api/v1/users/withdraw", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          if (data.message === "provide details")
            return notify(
              "error",
              "Please complete your profile first to make this withdrawal request"
            );
          notify("success", data.message);
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  }

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (
      e.target.deposit_amount?.value === "" ||
      e.target.deposit_amount?.value === 0
    )
      return;
    const data = {
      amount: e.target.deposit_amount.value,
    };

    submitDeposit(data);
    e.target.deposit_amount.value = "";
  };

  async function submitDeposit(data) {
    try {
      await fetch("/api/v1/mobipesa/transaction_reference", {
        method: "POST",
        body: JSON.stringify({
          phone_number: phoneNumber,
          amount: data.amount,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then(async (responseData) => {
          await fetch("/api/v1/mobipesa/stk_push", {
            method: "POST",
            body: new URLSearchParams({
              amount: data.amount ? data.amount : null,
              msisdn: phoneNumber ? phoneNumber : null,
              reference: responseData.reference,
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + token,
            },
          })
            .then(async (response) => {
              if (response.ok) {
                return response.json();
              }
              return response.text().then((text) => {
                throw new Error(text);
              });
            })
            .then((data) => {
              if (data.status === "success") {
                notify(
                  "success",
                  t("user-profile.notifications.deposit-success")
                );
              } else {
                notify("error", t("user-profile.notifications.deposit-error"));
              }
            })
            .catch((error) =>
              notify("error", t("user-profile.notifications.deposit-error"))
            );
        })
        .catch((error) => {
          notify("error", t("user-profile.notifications.deposit-error"));
        });
    } catch (error) {
      notify("error", t("user-profile.notifications.deposit-error"));
    }
  }

  const openDeleteModal = (e) => {
    e.preventDefault();
    toggle();
  };

  const handleUserProfileDelete = () => {
    try {
      fetch("api/v1/users/profile", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPlayer("");
          dispatch(logout());
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  };

  return (
    <>
      {!player ? (
        <Container className="col-12 bg-lottopawa-green min-vh-100" fluid>
          <Row className="justify-content-center">
            <div id="preloader2"></div>
          </Row>
        </Container>
      ) : (
        <>
          <Row className="mb-3">
            <Col xl="12">
              <Card className="bg-secondary shadow">
                <CardBody className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="5" className="d-flex flex-row">
                      <i className="fas fa-wallet fa-2x text-primary"></i>
                      <div className="d-flex flex-column pl-2">
                        <h3 className="mb-0">
                          {t("user-profile.fields.balance")}
                        </h3>
                        <h2 className="mb-0">
                          {player.wallet?.balance.toLocaleString("en-KE", {
                            style: "currency",
                            currency: "KES",
                            minimumFractionDigits: 2,
                          })}
                        </h2>
                      </div>
                    </Col>

                    <Col xs="1" className="justify-content-center">
                      <span className="border border-primary"></span>
                    </Col>

                    <Col xs="5" className="d-flex flex-row justify-content-end">
                      <i className="fas fa-gift fa-2x text-primary"></i>
                      <div className="d-flex flex-column pl-2">
                        <h3 className="mb-0">
                          {t("user-profile.fields.bonus")}
                        </h3>
                        {referralData && (
                          <h2 className="mb-0">
                            {Number(referralData?.silver_wheel) +
                              Number(referralData?.golden_wheel)}
                          </h2>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="order-xl-1 mb-2" xl="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-lottopawa-green border-0">
                  <h3 className="mb-0 text-capitalize text-lottopawa-yellow">
                    {t("user-profile.headings.deposit")}
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleDeposit}>
                    <h6 className="heading-small text-muted mb-4">
                      {t("user-profile.headings.deposit-info")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-amount"
                            >
                              {t("user-profile.fields.amount")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-amount"
                              placeholder="Amount"
                              type="number"
                              name="deposit_amount"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <Button
                            color="lottopawa-yellow text-dark"
                            type="submit"
                            className="mt-4"
                          >
                            <span className="btn-inner--icon text-dark">
                              <i className="fas fa-money-bill"></i>
                            </span>
                            <span className="btn-inner--text text-dark">
                              {t("user-profile.buttons.deposit")}
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-2" xl="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0 bg-lottopawa-green">
                  <h3 className="mb-0 text-capitalize text-lottopawa-yellow">
                    {t("user-profile.headings.withdraw")}
                    {player.wallet?.balance < 10 ? (
                      <span className="text-danger">
                        {" "}
                        ({t("user-profile.notifications.withdraw-disabled")})
                      </span>
                    ) : null}
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleWithdrawalRequest}>
                    <h6 className="heading-small text-muted mb-4">
                      {t("user-profile.headings.withdraw-info")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col className="" lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-withdraw-amount"
                            >
                              {t("user-profile.fields.amount")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-withdraw-amount"
                              placeholder="Amount"
                              type="number"
                              name="withdraw_amount"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mb-0">
                            <Button
                              className="btn-icon mt-4"
                              color="lottopawa-yellow"
                              disabled={
                                player.wallet?.balance < 10 ? true : false
                              }
                            >
                              <span className="btn-inner--icon text-dark">
                                <i className="fas fa-wallet"></i>
                              </span>
                              <span className="btn-inner--text text-dark">
                                {t("user-profile.buttons.withdraw")}
                              </span>
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-2 mb-2" xl="4">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0 bg-lottopawa-green">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0 text-lottopawa-yellow">
                        {t("user-profile.headings.change-password")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handlePasswordChange}>
                    <h6 className="heading-small text-muted mb-4">
                      {t("user-profile.fields.password")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-Password"
                            >
                              {t("user-profile.fields.password")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-Password"
                              placeholder={t("user-profile.fields.password")}
                              type="password"
                              name="password"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-PasswordConfirmation"
                            >
                              {t("user-profile.fields.confirm-password")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-PasswordConfirmation"
                              placeholder={t(
                                "user-profile.fields.confirm-password"
                              )}
                              type="password"
                              name="passwordConfirmation"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Button size="lg" block color="lottopawa-yellow">
                      <span className="btn-inner--icon text-dark">
                        {t("user-profile.buttons.change-password")}
                      </span>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0 bg-lottopawa-green">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0 text-lottopawa-yellow">
                        {t("user-profile.headings.my-account")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleUserProfileSubmit}>
                    <h6 className="heading-small text-muted mb-4">
                      {t("user-profile.headings.personal-info")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-phone-number"
                            >
                              {t("user-profile.fields.phone-number")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-phone-number"
                              defaultValue={player.phone_number}
                              placeholder="7123456789"
                              type="text"
                              disabled
                              name="phone_number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-email"
                            >
                              {t("user-profile.fields.email")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={player.user_email}
                              placeholder="email@lottopawa.co.ke"
                              type="email"
                              name="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-national_id"
                            >
                              {t("user-profile.fields.national-id")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-national_id"
                              defaultValue={player.national_id}
                              placeholder="12345678"
                              type="text"
                              name="national_id"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-first-name"
                            >
                              {t("user-profile.fields.first-name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={player.first_name}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              name="first_name"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-last-name"
                            >
                              {t("user-profile.fields.last-name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={player.last_name}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              name="last_name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      {t("user-profile.headings.contact-info")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label text-muted"
                              htmlFor="input-address"
                            >
                              {t("user-profile.fields.address")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={player.address}
                              id="input-address"
                              placeholder="address"
                              type="text"
                              name="address"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Button
                      size="lg"
                      block
                      color="lottopawa-yellow"
                      type="submit"
                    >
                      <span className="btn-inner--icon text-dark">
                        {t("user-profile.buttons.update-profile")}
                      </span>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5 mb-2">
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-danger border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0 text-lottopawa-yellow">
                        {t("user-profile.headings.danger-zone")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <p className="mb-3 text-muted">
                    {t("user-profile.texts.delete-account")}
                  </p>
                  <Form onSubmit={openDeleteModal}>
                    <Button color="danger" size="lg" block>
                      {t("user-profile.buttons.delete-account")}
                    </Button>
                  </Form>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader
                      toggle={toggle}
                      className="bg-lottopawa-footer"
                    >
                      <h4 className="text-lottopawa-yellow">
                        {t("user-profile.headings.delete-account-confirmation")}
                      </h4>
                    </ModalHeader>
                    <ModalBody className="bg-secondary text-muted">
                      {t("user-profile.texts.delete-account-confirmation")}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggle}>
                        {t("user-profile.buttons.cancel")}
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => {
                          toggle();
                          handleUserProfileDelete();
                        }}
                      >
                        {t("user-profile.buttons.delete")}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Profile;
