import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "views/Dashboard/Dashboard.js";
import Profile from "views/UserProfile/Profile.js";
import Ticket from "views/UserTicket/Tickets.js";
import CreateTicket from "views/UserTicket/CreateTicket.js";
import ViewTicket from "views/UserTicket/ViewTicket.js";
import MyTickets from "views/UserLottery/MyLotteries.js";
import ViewLottery from "views/UserLottery/ViewLottery.js";
import Transactions from "views/UserTransaction/Transactions.js";
import { t } from "i18next";

var routes = [
  {
    path: "/dashboard",
    name: t("menu.dashboard"),
    icon: "fas fa-gauge text-primary",
    component: Dashboard,
    layout: "/auth",
  },
  {
    path: "/my-tickets",
    name: t("menu.my-tickets"),
    icon: "fas fa-ticket text-warning",
    component: MyTickets,
    layout: "/auth",
  },
  {
    path: "/my-ticket/:ticket_id",
    name: t("menu.my-tickets"),
    icon: "fas fa-ticket text-warning",
    component: ViewLottery,
    layout: "/auth",
    ignore: true,
  },
  {
    path: "/my-profile",
    name: t("menu.my-profile"),
    icon: "fas fa-user text-dark",
    component: Profile,
    layout: "/auth",
  },
  {
    path: "/support-tickets",
    name: t("menu.tickets"),
    icon: "fas fa-list text-red",
    component: Ticket,
    layout: "/auth",
  },
  {
    path: "/support-ticket/new",
    name: "Create Ticket",
    icon: "fas fa-list text-red",
    component: CreateTicket,
    layout: "/auth",
    ignore: true,
  },
  {
    path: "/support-ticket/view/:id",
    name: "View Ticket",
    icon: "fas fa-list text-red",
    component: ViewTicket,
    layout: "/auth",
    ignore: true,
  },
  {
    path: "/transactions",
    name: t("menu.transactions"),
    icon: "fas fa-money-bill text-green",
    component: Transactions,
    layout: "/auth",
  },
];
export default routes;
