import CustomDatePicker from "components/Table/CustomDatePicker";
import TableComponent from "components/Table/Table.js";
import useDocumentTitle from "../../useDocumentTitle";
import { Row, Col, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MyLotteries = () => {
  useDocumentTitle("LottoPawa | My Tickets");
  const history = useHistory();
  const { t } = useTranslation();

  const columns = [
    {
      title: t("lotteries.fields.ticket-id"),
      field: "transaction_id",
      sorting: false,
    },
    {
      title: t("lotteries.fields.purchase-date"),
      field: "purchased_at",
      sorting: false,
      render: (rowData) =>
        new Date(rowData.purchased_at).toLocaleString("en-GB", {
          hour12: true,
        }),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("lotteries.fields.draw-date"),
      field: "draw_date",
      sorting: false,
      render: (rowData) => new Date(rowData.draw_date).toDateString(),
      filterComponent: (props) => <CustomDatePicker {...props} />,
    },
    {
      title: t("lotteries.fields.amount"),
      field: "price",
      sorting: false,
      render: (rowData) => {
        return rowData.price.toLocaleString("en-KE", {
          style: "currency",
          currency: "Ksh",
          minimumFractionDigits: 2,
        });
      },
    },
    {
      title: t("lotteries.fields.status"),
      field: "status",
      sorting: false,
      render: (rowData) => {
        if (rowData.status === "won") {
          return (
            <Badge color="success" className="badge-dot mr-4">
              <i className="bg-success" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "not-won") {
          return (
            <Badge color="danger" className="badge-dot mr-4">
              <i className="bg-danger" />
              {rowData.status}
            </Badge>
          );
        } else if (rowData.status === "pending-draw") {
          return (
            <Badge color="info" className="badge-dot mr-4">
              <i className="bg-info" />
              {rowData.status}
            </Badge>
          );
        }
      },
    },
    {
      title: t("lotteries.fields.win-amount"),
      field: "win_amount",
      filtering: false,
      sorting: false,
      render: (rowData) =>
        rowData.win_amount
          ? rowData.win_amount.toLocaleString("en-KE", {
              style: "currency",
              currency: "Ksh",
              minimumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("lotteries.fields.action"),
      field: "action",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <button
            className="btn btn-lottopawa-yellow"
            onClick={() => {
              history.push(`/my-ticket/${rowData.transaction_id}`);
            }}
          >
            {t("lotteries.fields.view")}
          </button>
        );
      },
    },
  ];

  return (
    <Row>
      <Col className="mb-3" xl="12">
        <TableComponent
          title={t("lotteries.my-lotteries")}
          columns={columns}
          url="/api/v1/userLotteries/all?"
        />
      </Col>
    </Row>
  );
};

export default MyLotteries;
