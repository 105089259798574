import { ThemeProvider, createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import React from "react";

const defaultMaterialTheme = createTheme();
const tableRef = React.createRef();

function Table(props) {
  const { t } = useTranslation();
  const columns = props.columns;
  const title = props.title;
  const token = useSelector((state) => state.auth.token);

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title={title}
          options={{
            debounceInterval: 1000,
            padding: "dense",
            filtering: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: "var(--lottopawa-footer)",
              color: "#FFF",
            },
          }}
          tableRef={tableRef}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              let url = props.url;
              //pagination
              url += `per_page=${query.pageSize}`;
              url += `&page=${query.page + 1}`;
              //searching
              if (query.search) {
                url += `&q=${query.search}`;
              }
              //sorting
              if (query.orderBy) {
                url += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
              }
              //filtering
              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  return `&${filter.column.field}${filter.operator}${filter.value}`;
                });
                url += filter.join("");
              }

              fetch(url, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              })
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.data,
                    page: result.pagination.currentPage - 1,
                    totalCount: result.pagination.totalCount,
                  });
                });
            })
          }
          actions={[
            {
              icon: t("table-component.actions.icon"),
              tooltip: t("table-component.actions.tooltip"),
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: `{from}-{to} ${t(
                "table-component.pagination.labelDisplayedRows"
              )} {count}`,
              labelRowsSelect: t("table-component.pagination.labelRowsSelect"),
              labelRowsPerPage: t(
                "table-component.pagination.labelRowsPerPage"
              ),
              firstAriaLabel: t("table-component.pagination.firstAriaLabel"),
              firstTooltip: t("table-component.pagination.firstTooltip"),
              previousAriaLabel: t(
                "table-component.pagination.previousAriaLabel"
              ),
              previousTooltip: t("table-component.pagination.previousTooltip"),
              nextAriaLabel: t("table-component.pagination.nextAriaLabel"),
              nextTooltip: t("table-component.pagination.nextTooltip"),
              lastAriaLabel: t("table-component.pagination.lastAriaLabel"),
              lastTooltip: t("table-component.pagination.lastTooltip"),
            },
            toolbar: {
              addRemoveColumns: t("table-component.toolbar.addRemoveColumns"),
              nRowsSelected: `{0} ${t(
                "table-component.toolbar.nRowsSelected"
              )}`,
              showColumnsTitle: t("table-component.toolbar.showColumnsTitle"),
              showColumnsAriaLabel: t(
                "table-component.toolbar.showColumnsAriaLabel"
              ),
              exportTitle: t("table-component.toolbar.exportTitle"),
              exportAriaLabel: t("table-component.toolbar.exportAriaLabel"),
              exportName: t("table-component.toolbar.exportName"),
              searchTooltip: t("table-component.toolbar.searchTooltip"),
              searchPlaceholder: t("table-component.toolbar.searchPlaceholder"),
            },
            header: {
              actions: t("table-component.header.actions"),
            },
            body: {
              emptyDataSourceMessage: t(
                "table-component.body.emptyDataSourceMessage"
              ),
              addTooltip: t("table-component.body.addTooltip"),
              deleteTooltip: t("table-component.body.deleteTooltip"),
              editTooltip: t("table-component.body.editTooltip"),
              filterRow: {
                filterTooltip: t(
                  "table-component.body.filterRow.filterTooltip"
                ),
              },
              editRow: {
                deleteText: t("table-component.body.editRow.deleteText"),
                cancelTooltip: t("table-component.body.editRow.cancelTooltip"),
                saveTooltip: t("table-component.body.editRow.saveTooltip"),
              },
            },
            grouping: {
              placeholder: t("table-component.grouping.placeholder"),
              groupedBy: t("table-component.grouping.groupedBy"),
            },
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default Table;
