import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authAction";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = useState();
  const [balance, setBalance] = useState(0);
  const token = useSelector((state) => state.auth.token);
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const dispatch = useDispatch();
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {prop.ignore ? null : (
            <NavLink
              to={prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="lottopawa-yellow"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          )}
        </NavItem>
      );
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
    };
  }

  async function clearCookies() {
    fetch("/cookies/clear-logout", {
      method: "GET",
    });
  }

  useEffect(() => {
    async function fetchBalance() {
      try {
        await fetch("/api/v1/users/balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setBalance(result.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchBalance();
  });

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-lottopawa-yellow opacity-9"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <i className="fa-solid fa-bars text-dark"></i>
          {/* <span className="navbar-toggler-icon" /> */}
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <i className="fas fa-user" />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem>
                <i className="fas fa-wallet" />
                <span>
                  {balance.toLocaleString("en-KE", {
                    style: "currency",
                    currency: "KES",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </DropdownItem>
              <DropdownItem to="/my-profile" tag={Link}>
                <i className="fas fa-user" />
                <span>{t("menu.my-profile")}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  clearCookies();
                  dispatch(logout());
                }}
              >
                <i className="fas fa-person-running" />
                <span>{t("menu.logout")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <Collapse
          navbar
          isOpen={collapseOpen}
          className="bg-lottopawa-yellow opacity-9"
        >
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem>
              <NavLink
                href="https://lottopawa.co.ke/"
                className="lottopawa-yellow"
              >
                <i className="fas fa-baby-carriage" />
                {t("menu.play")}
              </NavLink>
            </NavItem>
            {createLinks(routes)}
            <NavItem>
              <NavLink
                className="lottopawa-yellow"
                onClick={() => {
                  clearCookies();
                  dispatch(logout());
                }}
              >
                <i className="fas fa-person-running" />
                {t("menu.logout")}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
