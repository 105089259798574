import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";
import useDocumentTitle from "../../useDocumentTitle";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { t } from "i18next";

const ViewLottery = () => {
  useDocumentTitle("LottoPawa | View Ticket");
  const { ticket_id } = useParams();
  const [lottery, setLottery] = useState(null);
  const [draw, setDraw] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch(`/api/v1/userLotteries/${ticket_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            return response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((data) => {
            setLottery(data.tickets);
            setDraw(data.draw);
          });
      } catch (e) {
        console.error("Error fetching api data", e);
      }
    }
    fetchData();
  }, [ticket_id, token]);

  return (
    <>
      {!lottery ? (
        <Container className="col-12 bg-lottopawa-green min-vh-100" fluid>
          <Row className="justify-content-center">
            <div id="preloader2"></div>
          </Row>
        </Container>
      ) : (
        <Row className="m-auto">
          <Col className="mb-3" xl="12" xs="12">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <CardBody className="pt-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      {t("lotteries.fields.draw-result")}
                    </h3>
                    <h5 className="mt-1">
                      {draw?.draw_date &&
                        new Date(draw.draw_date).toLocaleDateString()}
                    </h5>
                  </div>
                </Row>

                {draw && (
                  <Row>
                    <Col className="mb-3 d-flex" xl="12">
                      {draw?.numbers?.split(",").map((number, index) => (
                        <span
                          key={index}
                          className="bg-lottopawa-yellow text-dark align-items-center d-flex justify-content-center mx-1"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        >
                          {number}
                        </span>
                      ))}
                      <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="PawaNumber"
                        className="bg-lottopawa-yellow text-dark align-items-center d-flex justify-content-center mx-4"
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      >
                        {draw?.pawa_number}
                      </span>
                    </Col>
                  </Row>
                )}

                {!draw && (
                  <Row>
                    <Col className="mb-3 d-flex" xl="12">
                      <h5 className="mt-1">
                        {t("lotteries.fields.not-drawn")}
                      </h5>
                    </Col>
                  </Row>
                )}

                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      {t("lotteries.fields.ticket-details")}
                    </h3>
                    <h6>
                      {t("lotteries.fields.purchase-date") +
                        ": " +
                        new Date(lottery[0]?.purchased_at).toDateString()}
                    </h6>
                    <h6>
                      {t("lotteries.fields.draw-date") +
                        ": " +
                        new Date(lottery[0]?.draw_date).toDateString()}
                    </h6>
                    <h6 className="mt-1">
                      {t("lotteries.fields.ticket-lines") +
                        ": " +
                        lottery.length}
                    </h6>
                  </div>
                </Row>

                {lottery.length > 0 &&
                  lottery?.map((line, index) => (
                    <Row key={index}>
                      <Col className="mb-3" xl="12">
                        <span className="row px-3">
                          {line.ticket_numbers.main
                            ?.split(",")
                            .map((number, index) => (
                              <span
                                key={index}
                                className={
                                  (draw?.numbers?.split(",").includes(number)
                                    ? "bg-lottopawa-yellow text-dark "
                                    : "bg-green-lottopawa text-white ") +
                                  "align-items-center d-flex justify-content-center mx-1"
                                }
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                }}
                              >
                                {number}
                              </span>
                            ))}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="PawaNumber"
                            className={
                              (line.ticket_numbers?.pawa_number ===
                              draw?.pawa_number
                                ? "bg-lottopawa-yellow text-dark "
                                : "bg-green-lottopawa text-white ") +
                              "align-items-center d-flex justify-content-center mx-4"
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          >
                            {line.ticket_numbers?.pawa_number}
                          </span>
                          <span>
                            {t("lotteries.fields.ticket-match") + ": "}
                            {line.ticket_numbers?.main
                              ?.split(",")
                              .filter((number) =>
                                draw?.numbers?.split(",").includes(number)
                              ).length +
                              (line.ticket_numbers?.pawa_number ===
                              draw?.pawa_number
                                ? " + 1"
                                : null)}
                            {" / 6 + 1"}
                          </span>
                          <span className="mx-3">
                            {t("lotteries.fields.ticket-status") + ": "}
                            <span
                              className={
                                line.status === "won"
                                  ? " text-success"
                                  : line.status === "not-won"
                                  ? " text-danger"
                                  : " text-info"
                              }
                            >
                              {line.status}
                            </span>
                          </span>
                          {line.status === "won" && line.win_amount && (
                            <span className="mx-3">
                              {t("lotteries.fields.ticket-prize") +
                                ": " +
                                line.win_amount?.toLocaleString("en-KE", {
                                  style: "currency",
                                  currency: "Ksh",
                                  minimumFractionDigits: 2,
                                })}
                            </span>
                          )}
                        </span>
                      </Col>
                    </Row>
                  ))}

                <hr className="my-3" />
                {(lottery.length > 0) &
                (lottery?.filter((line) => line.status === "won").length >
                  0) ? (
                  <Row>
                    <Col className="mb-3" xl="12">
                      {t("lotteries.fields.ticket-prize") + ": "}
                      {lottery.length > 0 &&
                        lottery
                          ?.filter((line) => line.status === "won")
                          .reduce((acc, line) => acc + line.win_amount, 0)
                          .toLocaleString("en-KE", {
                            style: "currency",
                            currency: "Ksh",
                            minimumFractionDigits: 2,
                          })}
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col className="mb-3" xl="12">
                    <Link to="/my-tickets">
                      <Button color="lottopawa-yellow" type="button">
                        {t("lotteries.fields.back-to-tickets")}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ViewLottery;
