import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { logout } from "../../actions/authAction";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AdminNavbar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchBalance() {
      try {
        await fetch("/api/v1/users/balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setBalance(result.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
    fetchBalance();
  });

  async function clearCookies() {
    fetch("/cookies/clear-logout", {
      method: "GET",
    });
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <p className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {t("menu.my-balance")}:{" "}
              <span className="text-lottopawa-yellow">
                {" " +
                  balance.toLocaleString("en-KE", {
                    style: "currency",
                    currency: "KES",
                    minimumFractionDigits: 2,
                  })}
              </span>
            </p>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle bg-lottopawa-yellow text-lottopawa-green">
                    <i className="fas fa-user" />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold text-capitalize">
                      {t("menu.logged-in")}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="bg-lottopawa-yellow" right>
                <DropdownItem to="/my-profile" tag={Link}>
                  <i className="fas fa-user" />
                  <span>{t("menu.my-profile")}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    clearCookies();
                    dispatch(logout());
                  }}
                >
                  <i className="fas fa-person-running" />
                  <span>{t("menu.logout")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
