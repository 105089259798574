import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const CustomDatePicker = (props) => {
  const [date, setDate] = useState(null);
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date picker"
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
          props.onFilterChanged(props.columnDef.tableData.id, newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
        componentsProps={{
          actionBar: {
            actions: [
              t("table-component.date.today"),
              t("table-component.date.clear"),
              t("table-component.date.cancel"),
            ],
          },
        }}
      />
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
